exports = module.exports = require("../../../../css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../resources/icons/icons-16.eot") + "?#iefix");
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../resources/icons/icons-16.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../resources/icons/icons-16.ttf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../../resources/icons/icons-20.eot") + "?#iefix");
var ___CSS_LOADER_URL___4___ = urlEscape(require("../../resources/icons/icons-20.woff"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("../../resources/icons/icons-20.ttf"));

// Module
exports.push([module.id, "@font-face{\n  font-family:\"Icons16\";\n  font-style:normal;\n  font-weight:normal;\n  src:url(" + ___CSS_LOADER_URL___0___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL___1___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL___2___ + ") format(\"truetype\"); }\n\n@font-face{\n  font-family:\"Icons20\";\n  font-style:normal;\n  font-weight:normal;\n  src:url(" + ___CSS_LOADER_URL___3___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL___4___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL___5___ + ") format(\"truetype\"); }", ""]);

