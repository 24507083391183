import React from 'react';
import { Text } from 'react-native';

export default class TabViewPagerAndroid<T: *> extends React.Component<
  Props<T>
> {
  render() {
    return (
      <Text>The TabViewPagerAndroid is not supported on React Native Web</Text>
    );
  }
}
